@media only screen and (min-width: 426px) and (max-width: 768px) {
  .logo-image-width{
    width: 150px;
  }
  .accordion-font h5{
    color: #494949;
    /* font-size: 5vw !important; */
  }
  .accordion-font h5 span{
    color: #000;
    display: block;
    font-weight: 500;
    /* font-size: 5vw !important; */
  }
  .nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #033ad2;
    padding: 5px 20px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 5%);
    transition: 0.3s ease-in-out;
    width: 100vw;
  }
  .slick-slide .sli-img {
    width: 60vw;
    height: 28vw;
    margin: 0px 30px 0px 0px !important;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 / 15%);
  }
  .nav-bar h5 {
    font-size: 2vw;
  }
  #nav-bar-links h4 {
    font-size: 1.5vw;
    color: #fff;
  }
  #nav-bar-links a {
    font-size: 1.5vw;
    color: #fff;
    text-decoration: underline;
  }
  #nav-bar-links a span {
    font-size: 1.5vw;
    color: rgb(201, 149, 29);
  }
  .carousel-div {
    position: relative;
    width: 100vw;
  }
  .carousel-div img {
    width: 100vw;
  }
  .first-section h1 {
    font-size: 4vw;
    color: #fff;
  }
  .second-section h4 {
    font-size: 3vw;
  }
  .container-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.5);
    padding: 0px;
    width: 600px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .pad-left {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .verify-background {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }
  .illustration {
    position: relative;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(1, 41, 112),
      rgba(37, 118, 184, 0.75)
    );
    text-align: -webkit-center;
    padding-top: 70px;
  }
  .illus-img img {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 150px;
  }
  .illust-img img {
    position: absolute;
    bottom: 0;
    right: 0px;
  }
  .container-wrapper {
    background: #fff;
    background-size: cover;
    background-position: center;
    margin: 0 !important;
    padding: 20px;
  }

  i.email {
    right: 20px;
    top: 13px;
    color: rgb(1, 49, 89, 0.5);
  }
  .login-img {
    width: 200px;
  }
  input {
    height: 40px;
    text-align: center;
    background: none;
    border: none;
    color: #013159;
    font-size: 12px;
  }

  input.email {
    border: 2px solid #013159;
    border-radius: 50px;
  }
  i.password {
    left: 20px;
    top: 13px;
    color: rgb(1, 49, 89, 0.5);
  }

  input.password {
    border: 2px solid #013159;
    border-radius: 50px;
  }
  button.submit {
    background: none;
    border: none;
    border: 2px solid #013159;
    color: #013159;
    font-size: 12px;
    border-radius: 50px;
    width: 170px;
    padding: 5px 0px;
  }
  button.submit:hover {
    color: white;
    background: #013159;
  }
  input[type="checkbox"] {
    border: 2px solid #013159;
    background: none;
  }

  label {
    color: #013159;
    font-size: 12px;
  }

  button.action {
    font-size: 12px;
    color: #013159;
    background: none;
    border: 2px solid #013159;
    border-radius: 20px;
    padding: 5px 0px;
  }
  button.action:hover {
    color: white;
    background: #013159;
  }

  h1 {
    color: #033ad2;
  }

  ::-webkit-input-placeholder {
    color: rgb(1, 49, 89, 0.5);
  }

  input:focus {
    outline: none;
  }
  .illust-text h4 {
    color: #fff;
    font-size: 50px;
  }
  .illust-text h4 span {
    color: rgb(201, 149, 29);
  }
  .enroll-font {
    font-size: 16px;
  }
  .img-backbo {
    background: url("../../../../public/assets/images/backblue.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    position: absolute;
  }
  .table-height2 {
    margin: 20px auto;
    width: 95%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .table-height4 {
    margin: 40px auto;
    width: 100%;
    border-radius: 5%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .table-height {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: transparent;
    padding: 0 !important;
  }
  .advert-margin {
    padding: 0px 10px;
  }
  .line {
    width: 15vw;
    height: 1px;
    background: #000;
  }
  .line1 {
    margin-top: 60px;
    width: 400px;
    height: 1px;
    background: #000 !important;
  }
  .line4 {
    width: 80vw;
    height: 1px;
    background: #000;
  }
  .line3 {
    width: 35vw;
    height: 1px;
    background: #000;
  }
  .marg-top {
    margin-top: 100px;
  }
  .enroll-box {
    width: 200px;
    height: 30px;
    border: 1px solid #000;
  }
  .formtype h4 {
    font-size: 16px;
  }
  .form-cover {
    border: 1px solid #033ad2;
  }

  .img-cover {
    margin: 20px auto;
    width: 300px;
    height: 300px;
    border: 1px solid #033ad2;
    overflow: hidden;
  }
  .main-cover {
    width: 300px;
    margin: 20px auto;
  }
  .img-cover img {
    width: 300px;
    height: 300px;
  }
  #pic-upload {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .head-cover {
    text-transform: uppercase;
  }
  .admiss {
    border: 1px solid #000;
    width: 200px;
    margin: 10px auto;
    padding: 10px 0px;
  }
  .wrapper1 {
    width: 20vw;
    height: 20vw;
    border: 1px solid #000;
    margin-top: -100px;
    overflow: hidden;
  }
  .wrapper1 img {
    width: 20vw;
    height: 20vw;
  }
  .img-position {
    width: 60px;
    height: 60px;
    margin: 2px auto;
  }
  .table-height3 {
    width: 100%;
    box-shadow: 0px 3px 10px 0px rgb(0 0 0 /0.15);
  }
  .body-content h4 {
    font-size: 13px;
    font-weight: 700;
  }
  .body-content h4 span {
    font-weight: 100;
  }
  .body-content h3 {
    font-size: 16px;
    font-weight: 600;
    text-align: -webkit-center;
  }
  .div-content {
    margin-top: 10px;
    width: 100%;
    display: flex;
  }
  .div-content-1 {
    width: 66.66%;
  }
  .div-content-2 {
    width: 33.33%;
  }
  .div-content-3 {
    width: 50%;
  }
  .div-content-cover {
    padding: 10px;
    border: 1px solid #000;
  }
  .div-content-fit {
    width: 50%;
  }
  .div-content-fit span{
    display: block;
    font-size: 13px;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit p{
    font-size: 13px;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit1 {
    width: 100%;
  }
  .div-content-fit1 span{
    display: block;
    font-size: 13px;
    color: #000;
    font-weight: 500;
  }
  .div-content-fit1 p{
    font-size: 13px;
    color: #000;
    font-weight: 500;
  }
  .space-line{
    width: 100%;
    height: 1px;
    background: #000000;
  }
  .d-width{
    width: fit-content;
  }
  .stu_id h1{
    font-size: 25px;
  }
}
